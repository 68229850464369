import { useCallback, useMemo, useRef } from 'react'
import { useLinkedIn } from 'react-linkedin-login-oauth2'
import env from 'src/@legacy/utilities/env'

export const useLinkedinLogin = ({ onLoginCode }: { onLoginCode: (code: string) => Promise<any> }) => {
  const currentPromise = useRef<{ resolve: () => void; reject: (err: any) => void }>()
  const { linkedInLogin: callLinkedin } = useLinkedIn(
    useMemo(
      () => ({
        clientId: env.NEXT_PUBLIC_LINKEDIN_CLIENT_ID,
        redirectUri: `${typeof window === 'object' && window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
        onSuccess: (code) => {
          onLoginCode(code)
            .then(() => {
              currentPromise.current.resolve()
            })
            .catch((error) => {
              currentPromise.current.reject(error)
            })
        },
        onError: (error) => {
          currentPromise.current.reject(error)
        },
        scope: 'r_liteprofile,r_emailaddress,w_member_social'
      }),
      [onLoginCode]
    )
  )
  const linkedInLogin = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      callLinkedin()
      currentPromise.current = { resolve, reject }
    })
  }, [])
  return { linkedInLogin }
}
export default useLinkedinLogin
